canvas {
  width: 100%;
  height: 100%;
}

#home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
}

.flex {
  /* z-index:1; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.canvas {
  height: 100vh;
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
}
.view-work-btn{
  font: inherit;
    padding: 0.5rem 1.5rem;
    border: 3px solid #fff;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    margin-right: 1rem;
    text-decoration: none;
    display: inline-block;
    font-size: large;
  z-index: 1;
  background: transparent;
  color: #fff;
  width: 50%;
  height: 50px;
}
.highlight {
  font-size: 2.5rem;
  font-weight: 700;
  color: #ff2e63;
  display: block;
}


@media screen and (min-width:600px){
  .highlight{
    font-size: 5rem;
  }
  .view-work-btn{
    width: 25%;
  }
}