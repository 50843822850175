#about{
    padding:3% 15% 5%;
    border-style: inset hidden hidden hidden;
    border-color: #112d4e;
}

.VictoryContainer{
    width: 20%
}

.headshot{
    width: 50%;
    border-radius: 50%;
    margin-bottom: 25px;
}
.chart-header{
    margin-bottom: 20px;
}

.col-lg{
    text-align:center;
    padding: 15px;
    width:100%;
}

.animation-class-about-blurb{
    animation-name: move-left;
}
.about-blurb{
    position: relative;
    animation-duration: 1.5s;
    visibility: visible;
    z-index:10;
    animation-fill-mode: forwards;
    width: 100%
}


.animation-class-bar-graph{
    animation-name: move-right;
}
.bar-graph{
    position: relative;
    animation-duration: 1.5s;
    visibility: visible;
    z-index:10;
    animation-fill-mode: forwards;
}

@media screen and (min-width:600px){
    .headshot{
        width: 25%;
        margin-bottom: 75px;
    }
    .about-blurb{
        width: 85%
    }

}

@keyframes move-right{
    0% { left:1000px; top:0px;}
    100% {left:0px; top:0px;}
}
@keyframes move-left{
    0% { right:1000px; top:0px;}
    100% {right:0px; top:0px;}
}