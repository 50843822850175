footer{
    background-color: black;
    min-height: 10vh;
    padding: 1% 25%;
}

footer .row{
    margin: 10px;
}

.fab{
    /* background-color: red; */
    color: #fff;
}

@media screen and (min-width:600px){
    .footer-icon{
        max-width: 16.6667%;
    }
}