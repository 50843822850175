/* Bar Graph Horizontal */
.bar-graph .bar-div {
  -webkit-animation: fade-in-text 2.2s 0.1s forwards;
  -moz-animation: fade-in-text 2.2s 0.1s forwards;
  animation: fade-in-text 2.2s 0.1s forwards;
  opacity: 0;
  /* background-color: #eee; */
  height: px;
  width: 110%;
}
.bar-col{
  right:5%;
}

.bar-graph-horizontal {
  max-width: 100%;
}

.bar-graph-horizontal > div {
  float: left;
  margin-bottom: 18px;
  width: 100%;
}

.bar-graph-horizontal .label {
  float: left;
  /* margin-top: 15px; */
  width: 100px;
  background-color: #112d4e;
  border-radius: 0;
  color: #fff;
  
}

.bar-graph-horizontal .bar {
  border-radius: 0px;
  height: 24px;
  float: left;
  overflow: hidden;
  position: relative;
}

.animation-class-bars{
  -webkit-animation: fade-in-text 2.2s 1s forwards;
  -moz-animation: fade-in-text 2.2s 1s forwards;
  animation: fade-in-text 2.2s 1s forwards;
  -webkit-animation: show-bar 1.2s 0.1s forwards;
  -moz-animation: show-bar1.2s 0.1s forwards;
  animation: show-bar 1.2s 0.1s forwards;
}
.bar-graph .bar::after {
  animation-delay: 5s;
  color:black;
  content: attr(data-percentage);
  font-weight: 700;
  position: absolute;
  right:10px;
  top: 0px;
}

.bar-graph .bar-div .bar {
  padding: 0 15px;
  background-color: #3f72af;
  
}

@media screen and (min-width:600px){
  .bar-graph .bar-div{
    width: 100%;
  }
  .bar-col{
    right:0;
  }
}
@keyframes show-bar {
  0% {
    width: 0;
  }
  100% {
    width: calc(attr(data-percentage) - 100px);
  }
}


@keyframes fade-in-text {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

