#portfolio{
    padding:5% 15% 5%;
    border-style: inset hidden hidden hidden;
    border-color: #112d4e;
    min-height: 90vh;
}

.card{
    padding: 2%;
    margin: 2%;
    background-color: #fff;
    border-color: #112d4e;
}
p{
    color: black
}

.animation-class-portfolio{
    transform: translateY(150px);
    animation: move-up 2s ease forwards;
}

.slide-in-bottom{
    position: relative;
    animation-duration: 1.5s;
    visibility: visible;
    z-index:10;
    /* animation-fill-mode: forwards; */
}

a.btn{
    background-color: #112d4e;
    width: 50%;
    color: #f9f7f7;
}

@keyframes move-up{
    
    to {transform: translateY(0);}
}