#contact{
  padding:5% 15% 5%;
  background-color: #112d4e;
  color: #f8f8f8;
  min-height:85vh;
  top: 5%;  
}

#contact .container{
  margin-top: 20%;
}
svg{
  left: 0;
  position: absolute;
  top: 0;
}
.email-prompt{
  color: #eee;
}
.email-sent{
  color:#50C878; 
}
path{
  background-color: #f8f8f8;
  fill:#eaeaea;
  
}
form {
  width: 100%;
}

@media screen and (min-width:600px){
  form{
    width: 50%;
  }
  #contact .container{
    margin-top: 0%;
  }
}