.App {
  text-align: center;
  
}
html{
  scroll-behavior: smooth;
}

section{
  min-height: 100vh;
  width: 100vw;
  position: relative;
  display: block;
}
body{
  color: #222831;
  font-family: 'Roboto', sans-serif;
  
}

.overflow-wrap{
  overflow-x: hidden;
}
.flex{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header{
  padding-bottom: 5%;
  margin-bottom: 20px;
}

h1, h2, h3, h4, h5{
  font-family: 'Merriweather', serif;
  z-index: 1;
  text-align: center;
  margin: 0;
}
h2{
  text-decoration: underline;
  font-size: 3rem;
  font-weight: 500;
}
h1{
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 10px;
}

section:nth-child(odd){
  background-color: #eaeaea;
}
section:nth-child(even){
  background-color: #f8f8f8;
}


.come-in {
  transform: translateY(150px);
  animation: come-in 0.8s ease forwards;
}
.come-in:nth-child(odd) {
  animation-duration: 0.6s;
}
.already-visible {
  transform: translateY(0);
  animation: none;
}

@keyframes come-in {
  to { transform: translateY(0); }
}

@media screen and (min-width:600px){
  h1{
    font-size: 5rem;
    margin-bottom: 20px;
  }

  h2{
    font-size:4rem;
  }
  .header{
    margin-bottom: 40px;
  }
}

